import { useState } from "react"
import AdvancedWizard from "src/components/AdvancedWizard"
import Dialog from "src/components/Dialog"
import useTranslation from "src/hooks/useTranslation"
import { closeType } from "src/macrocomponents/CreateAudit"
import SprintInformation from "./SprintInformation"

import styles from "../styles.module.scss"
import SprintCopyFrom from "./SprintCopyFrom"

export const SprintCreate = ({ defaultValues, onSubmit, onClose }) => {
  const [generalInformationData, setGeneralInformationData] = useState({})

  const { t } = useTranslation()

  const onCloseHandler = (type) => {
    onClose(type)
  }

  const goNextHandler = (goNext, data) => {
    setGeneralInformationData(data)
    goNext()
  }

  const onCreateSprint = ({ ids }) => {
    onSubmit({ ...generalInformationData, gentipo: "c", gentipo_param: { ids } })
  }

  return (
    <Dialog isOpen style={{ maxWidth: "80vw", maxHeight: "90vh", display: "flex", flexDirection: "column" }}>
      <Dialog.Header theme='light' buttonClose handleClose={onCloseHandler.bind(this, closeType.CLOSE)}>
        <h4>{t("frontend.auditoria.sprint.btn_add")}</h4>
      </Dialog.Header>
      <Dialog.Body background='#fff' color='#424242' style={{ flex: 1, overflow: "hidden" }}>
        <div className={styles.container}>
          <AdvancedWizard
            initSelected={0}
            displaySteps={["generalInformation", "creationType"]}
            maxSteps={2}
            config={{
              generalInformation: {
                headerLabel: t("frontend.auditoria.sprint.forms.creation.wizard_step1_title"),
                content: ({ goNext }) => {
                  return <SprintInformation defaultValues={defaultValues} onNext={goNextHandler.bind(this, goNext)} />
                }
              },
              creationType: {
                headerLabel: t("frontend.auditoria.sprint.forms.creation.wizard_step2_title"),
                content: ({ goBack }) => {
                  return <SprintCopyFrom onBack={goBack} onCreate={onCreateSprint} />
                }
              }
            }}
          />
        </div>
      </Dialog.Body>
    </Dialog>
  )
}
