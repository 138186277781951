import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Button from "src/components/Button"
import FormField from "src/components/FormField"
import Select, { formatChoices } from "src/components/FormField/Select"
import useTranslation from "src/hooks/useTranslation"
import ProjectTree from "src/macrocomponents/ProjectTree"

export default function SprintCopyFrom({ onCreate, onBack }) {
  const [selectedSprint, setSelectedSprint] = useState(null)
  const [selectedIds, setSelectedIds] = useState(null)

  const auditData = useSelector((store) => store.project)
  const sprints = auditData.sprints

  const { t } = useTranslation()

  useEffect(() => {
    setSelectedSprint(sprints[sprints.length - 1].id)
  }, [])

  const handleChangeSprint = ({ id }) => {
    setSelectedSprint(id)
  }

  const handleChange = (ids) => {
    setSelectedIds(ids)
  }

  const handleSubmit = () => {
    onCreate({ ids: selectedIds })
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: 10, margin: "10px 0" }}>
        <div style={{ display: "inline-flex", justifyContent: "center" }}>
          <FormField label={t("frontend.auditoria.sprint.forms.creation.wizard_step2_field_sprint")}>
            <Select
              selectionChange={handleChangeSprint}
              initialOptions={formatChoices(
                sprints.map((x) => {
                  return { id: x.id, label: x.nombre }
                }),
                selectedSprint
              )}
            />
          </FormField>
        </div>
        <FormField label={t("frontend.auditoria.sprint.forms.creation.wizard_step2_description")} border={false}>
          <div style={{ maxHeight: "40vh", overflowY: "auto", minHeight: 200 }}>
            {selectedSprint && (
              <ProjectTree
                projectId={auditData.id}
                projectType='auditoria'
                projectFilters={{ sprint: selectedSprint }}
                onSelect={handleChange}
                autoSelectChildren
                multiple
              />
            )}
          </div>
        </FormField>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 10, flex: 1 }}>
        <Button text='btn_back' className='ui button' handleClick={() => onBack()} />
        <Button
          text='btn_save'
          className='ui button primary'
          handleClick={handleSubmit}
          disabledText={{
            "frontend.auditoria.sprint.forms.creation.wizard_step2_description": selectedIds === null
          }}
        />
      </div>
    </>
  )
}
