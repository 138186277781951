import cx from "classnames"
import { useState } from "react"
import { Checkbox } from "semantic-ui-react"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export default function InputYesNoChoice({
  disabled = false,
  trueLabel = "main_ui.general.lb_yes",
  falseLabel = "main_ui.general.lb_no",
  initialValue = null,
  onChange,
  className,
  style = {}
}) {
  const [value, setValue] = useState(initialValue)

  const { t } = useTranslation()

  const handleChange = (ev, v) => {
    handleLabelClick(v.checked)
  }

  const handleLabelClick = (checked) => {
    return (ev) => {
      if (disabled) {
        return null
      }

      setValue(checked)
      onChange(checked ? 1 : 0)
    }
  }

  return (
    <div>
      <span className={cx(styles.lb, styles.lbFalse, className)} style={style} onClick={handleLabelClick(false)}>
        {t(falseLabel)}
      </span>
      <Checkbox checked={value} onChange={handleChange} toggle />
      <span className={cx(styles.lb, styles.lbTrue, className)} style={style} onClick={handleLabelClick(true)}>
        {t(trueLabel)}
      </span>
    </div>
  )
}
