import cx from "classnames"
import { useEffect, useState } from "react"
import { Confirm } from "semantic-ui-react"
import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Helptip from "src/components/Helptip"
import RadioGroup from "src/components/RadioGroup"
import RadioButton from "src/components/RadioGroup/RadioButton"
import TabTwo from "src/components/TabTwo"
import useTranslation from "src/hooks/useTranslation"
import { auditTemplatesServices } from "src/services/audit.service"
import CopyExistingAudit from "./CopyExistingAudit"
import styles from "./style.module.scss"

const riskTemplate = [
  {
    label: "Auditoría Gestión de Proyectos",
    value: "gestion_proyectos"
  },
  {
    label: "Evaluación del Sistema de Control Interno",
    value: "control_interno"
  },
  {
    label: "Sistema de Gestión ISO 9001:2015",
    value: "ISO9001"
  }
]

export default function CreationType({ onBack = () => {}, onCreate = () => {}, additionalData = {} }) {
  const [copyWarningOpen, setCopyWarningOpen] = useState(false)

  const [tabSelected, setTabSelected] = useState({
    index: 0,
    id: "n"
  })

  const [param, setParam] = useState({ n: null, c: null })

  const [templates, setTemplates] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    auditTemplatesServices()
      .LIST()
      .then(({ data }) => {
        setTemplates(data)
      })
  }, [])

  const goBackHandler = () => {
    onBack()
  }

  const onCreateHandler = () => {
    if (param[tabSelected.id] === null) {
      return false
    }

    if (tabSelected.id == "c" && !param[tabSelected.id]?.copy_options) {
      setCopyWarningOpen(true)
      return false
    }

    onCreate({
      gentipo: tabSelected.id,
      gentipo_param: param[tabSelected.id]
    })
  }

  const setParamByTab = (tabId, v) => {
    setParam((last) => {
      const updatedData = { ...last }
      updatedData[tabId] = v
      return updatedData
    })
  }

  const onConfirmEmptyCopyOptions = () => {
    setCopyWarningOpen(false)
    onCreate({
      gentipo: tabSelected.id,
      gentipo_param: param[tabSelected.id]
    })
  }

  return (
    <div className={styles.body}>
      <div style={{ display: "flex", flexDirection: "column", gap: 5, marginTop: 6, marginBottom: 20, height: "55vh" }}>
        <div style={{ margin: "20px 0 10px" }}>
          A continuación seleccione el método por el cual desea crear la Auditoría:
        </div>
        <TabTwo
          style={{ height: "90%", rowGap: 5, overflowY: "auto" }}
          headerStyle={{ justifyContent: "space-around", marginBottom: 10 }}
          onChange={({ selectedTabId, selectedTabIndex }) => {
            setTabSelected({ index: selectedTabIndex, id: selectedTabId })
          }}
          config={[
            {
              id: "n",
              headerLabel: (
                <div className={styles.headerWrapper}>
                  <span>Desde Plantilla</span>
                  <Helptip
                    name='auditoria.new_form.tipo_creacion.plantilla'
                    title='Desde Plantilla'
                    tooltipPosition='right'
                  />
                </div>
              ),
              content: (
                <div className='ui container' style={{ maxWidth: 600, marginBottom: 20 }}>
                  <div style={{ display: "flex", flexDirection: "column", gap: 5, marginTop: 5, height: "100%" }}>
                    <Divider />

                    {templates === null ? (
                      <div className='ui segment basic' style={{ minHeight: 180 }}>
                        <div className='ui loader active small'></div>
                      </div>
                    ) : (
                      <RadioGroup
                        color='#0a858d'
                        className={cx("ui vertical menu link fluid", styles.templatesRadiolist)}
                        onRadioChange={(val) => {
                          setParamByTab("n", val)
                        }}
                      >
                        {templates?.length > 0 && (
                          <div className='item header'>{t("frontend.auditoria.template.choices.custom")}</div>
                        )}
                        {templates?.map((template) => {
                          return (
                            <div className={cx("item", styles.customTemplate)} key={template.id}>
                              <RadioButton value={`@template:${template.id}`}>{template.name}</RadioButton>
                            </div>
                          )
                        })}

                        <div className='item header'>{t("frontend.auditoria.template.choices.from_excel")}</div>
                        <div className='item'>
                          <RadioButton value=''>
                            {t("frontend.auditoria.template.choices.from_excel__fullname")}
                          </RadioButton>
                        </div>

                        <div className='item header'>{t("frontend.auditoria.template.choices.default")}</div>
                        {riskTemplate.map((el, k) => {
                          return (
                            <div className='item' key={k}>
                              <RadioButton value={el.value}>{el.label}</RadioButton>
                            </div>
                          )
                        })}
                      </RadioGroup>
                    )}
                  </div>
                </div>
              )
            },
            {
              id: "c",
              headerLabel: (
                <div className={styles.headerWrapper}>
                  <span>Copiar Existente</span>
                  <Helptip
                    name='auditoria.new_form.tipo_creacion.copia'
                    title='Copiar existente'
                    tooltipPosition='right'
                  />
                </div>
              ),
              content: (
                <div style={{ height: "99%", marginBottom: 20 }}>
                  <CopyExistingAudit onChange={(value) => setParamByTab("c", value)} />
                </div>
              )
            }
          ]}
        />
      </div>
      <div style={{ display: "flex", alignItems: "flex-end", paddingTop: 10, flex: 1 }}>
        <Button text='Anterior' name='green' type='big' handleClick={goBackHandler} />
        <span style={{ flex: "1" }} />
        <Button
          text='Crear Auditoría'
          name='normal'
          type='big'
          disabledText={{
            "Seleccione una plantilla con la metodología a utilizar":
              tabSelected.id == "n" && param[tabSelected.id] === null,
            "Seleccione un proyecto a copiar": tabSelected.id == "c" && param[tabSelected.id] === null
          }}
          handleClick={onCreateHandler}
        />
      </div>
      {copyWarningOpen && (
        <Confirm
          content={
            <div style={{ marginTop: 10, padding: 20 }}>
              <p style={{ marginBottom: 10 }}>
                <span className='ui label orange' style={{ padding: ".3em .6em .3em" }}>
                  Advertencia:
                </span>{" "}
                Usted no ha seleccionado ninguna opción a copiar, por lo tanto se copiará únicamente la estructura de la
                auditoría (Macroprocesos, Procesos y actividades), pero sin las hojas de trabajo asociados.
              </p>
              ¿Desea continuar?
            </div>
          }
          size='tiny'
          cancelButton='Cancelar'
          confirmButton='Continuar'
          open
          onCancel={() => setCopyWarningOpen(false)}
          onConfirm={() => onConfirmEmptyCopyOptions()}
        />
      )}
    </div>
  )
}
