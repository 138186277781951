import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import AdvancedTable from "src/components/AdvancedTable"
import Button from "src/components/Button"
import InputSearch from "src/components/InputSearch"
import Loading from "src/components/Loading"
import Pill from "src/components/Pill"
import RadioButtonAlone from "src/components/RadioGroup/RadioButtonAlone"
import { mapRiskEvaluation } from "src/helpers/risk"
import { logoutCallback } from "src/services/authValidationMiddleware"
import { getEvaluations } from "src/services/evaluation.service"
import useDispatch from "src/store"

export default function RiskEvaluation({ onNext = () => {}, onBack = () => {} }) {
  const router = useRouter()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)

  const getData = async (page = 1, search = "", entriesPerPage = 10000) => {
    setIsLoading(true)

    const res = await getEvaluations({ page, entriesPerPage }, logoutCallback(dispatch, router))

    const { records } = res.data

    records.forEach((el) => {
      el.checked = false
    })
    setData(records)

    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const goNextHandler = () => {
    const selectedEvaluation = data.find((el) => el.selected === true)

    if (!selectedEvaluation) return false

    onNext({
      selectedEvaluationId: selectedEvaluation.id
    })
  }
  const goBackHandler = () => {
    onBack()
  }

  const onFilterHandler = () => {}

  const hasSelected = data && !!data.find((el) => el.selected === true)

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          display: "grid",
          gap: 15,
          height: "100%",
          overflow: "hidden",
          alignContent: "start"
        }}
      >
        <div style={{ margin: "10px 0 0" }}>
          A continuación relacione la evaluación de riesgos que considere se acople con su nueva Auditoría
        </div>

        {isLoading ? (
          <Loading text='Cargando evaluaciones' centered={false} />
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
              <InputSearch style={{ flex: 1 }} />
            </div>
            <AdvancedTable
              data={data}
              displayedColumns={["radio", "id", "auditName", "auditType", "processType", "startDate", "status"]}
              config={{
                rows: {
                  radio: {
                    cell: (row) => {
                      return (
                        <RadioButtonAlone
                          color='#0a858d'
                          selected={row.selected}
                          onClick={() => {
                            const updatedData = [...data]

                            updatedData.forEach((el) => {
                              el.selected = false

                              if (el.id === row.id) {
                                el.selected = true
                              }
                            })

                            setData(updatedData)
                          }}
                        />
                      )
                    }
                  },
                  id: {
                    headerCell: <div>ID</div>,
                    cell: (row) => {
                      return <div>{row.codigo}</div>
                    }
                  },
                  auditName: {
                    headerCell: <div>Nombre de la Evaluación</div>,
                    cell: (row) => {
                      return <div>{row.nombre}</div>
                    }
                  },
                  auditType: {
                    headerCell: <div>Empresa</div>,
                    cell: (row) => {
                      return <div>{row.empresa?.nombre}</div>
                    }
                  },
                  processType: {
                    headerCell: <div>Unidad de Negocio</div>,
                    cell: (row) => {
                      return <div>{row.unidad_negocio?.nombre}</div>
                    }
                  },
                  startDate: {
                    headerCell: <div>Año de Evaluación</div>,
                    cell: (row) => {
                      return <div>{row.ano_evaluacion}</div>
                    }
                  },
                  status: {
                    headerCell: <div>Estado</div>,
                    cell: (row) => {
                      const found = mapRiskEvaluation(row.status)

                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5
                          }}
                        >
                          <Pill color={found.color} />
                          {found.label}
                        </div>
                      )
                    }
                  }
                }
              }}
              onRowClick={(row) => {
                const updatedData = [...data]

                updatedData.forEach((el) => {
                  el.selected = false

                  if (el.id === row.id) {
                    el.selected = true
                  }
                })

                setData(updatedData)
              }}
              showPaginator={false}
              stickyHeader
            />
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          paddingTop: 10,
          flex: 1
        }}
      >
        <Button text='btn_prev' name='green' type='big' handleClick={goBackHandler} />
        <span style={{ flex: "1" }} />
        <Button text='btn_next' name='normal' type='big' disabled={!hasSelected} handleClick={goNextHandler} />
      </div>
    </div>
  )
}
