import React from "react"
import Dialog from "src/components/Dialog"
import DialogBody from "src/components/Dialog/DialogBody"
import Loading from "src/components/Loading"

export default function CreationModal({ title = "", note = "" }) {
  return (
    <Dialog
      isOpen
      style={{ minWidth: "50vw", height: "auto" }}
      dialogStyle={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}
    >
      <DialogBody
        style={{
          backgroundColor: "none",
          display: "grid",
          textAlign: "center",
          color: "#e6e6e6",
          lineHeight: 1
        }}
      >
        <h3 style={{ fontSize: 24, color: "#fff", marginBottom: 10 }}>
          {title}
        </h3>
        <Loading
          color='#fff'
          centered={false}
          type='HashLoader'
          height={30}
          style={{ marginTop: 30, marginBottom: 20 }}
        />
        {note && (
          <p style={{ lineHeight: 2, maxWidth: 500, margin: "0px auto" }}>
            <span style={{ fontWeight: "bold", color: "#fff", fontSize: 18 }}>
              Nota:{" "}
            </span>
            {note}
          </p>
        )}
      </DialogBody>
    </Dialog>
  )
}
