import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { TextArea } from "semantic-ui-react"
import Button from "src/components/Button"
import FormErrors from "src/components/FormErrors"
import FormField from "src/components/FormField"
import Input from "src/components/FormField/Input"
import InputYesNoChoice from "src/components/FormField/YesNoChoice"
import useTranslation from "src/hooks/useTranslation"
import RangeDatePicker from "src/macrocomponents/RangeDatePicker"
import styles from "../styles.module.scss"

export default function SprintInformation({ onNext, defaultValues, initData }) {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues })

  const { t } = useTranslation()

  const formValidations = useMemo(
    () => ({
      nombre: { required: true },
      fecha_inicio: { required: true },
      fecha_fin: { required: true },
      horas_presupuestadas: { required: true },
      objetivo: { required: false },
      "configs.affect_progress": { required: false }
    }),
    []
  )

  const onSubmit = async (data) => {
    onNext(data)
  }

  useEffect(() => {
    Object.keys(formValidations).forEach((field) => {
      register(field, formValidations[field])
    })
  }, [register, formValidations])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "grid",
          gap: 15,
          overflow: "auto",
          paddingBottom: 10
        }}
      >
        <div style={{ margin: "20px 0 10px" }}>
          {t("frontend.auditoria.sprint.forms.creation.wizard_step1_description")}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 15, alignItems: "center" }}>
          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              {t("frontend.auditoria.sprint.fields.name__long")}
              <span className={styles.required}>*</span>
            </label>
            <FormField classRequired={errors.nombre ? true : false}>
              <Input
                initialValue={defaultValues.nombre ?? ""}
                onChange={(value) => {
                  setValue("nombre", value, { shouldValidate: true })
                }}
              />
            </FormField>
            <FormErrors errors={errors.nombre} />
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 15 }}>
          <RangeDatePicker
            labelConfig={{
              start: (
                <>
                  {t("frontend.default.start_date")}: <span className={styles.required}>*</span>
                </>
              ),
              end: (
                <>
                  {t("frontend.default.end_date")}: <span className={styles.required}>*</span>
                </>
              ),
              position: "up",
              bold: true,
              twoPoints: false,
              style: {
                width: "fit-content"
              }
            }}
            format='yyyy-MM-dd'
            initialStartDateValue={""}
            initialEndDateValue={""}
            startChange={([completeDate, visualDate]) => {
              setValue("fecha_inicio", visualDate, {
                shouldValidate: true
              })
            }}
            endChange={([completeDate, visualDate]) => {
              setValue("fecha_fin", visualDate, {
                shouldValidate: true
              })
            }}
            errorsFormStart={errors.fecha_inicio}
            errorsFormEnd={errors.fecha_fin}
          />
          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              {t("frontend.default.estimated_hours")} <span className={styles.required}>*</span>
            </label>
            <FormField classRequired={errors.horas_presupuestadas ? true : false}>
              <Input
                type='number'
                min={0}
                initialValue={""}
                onChange={(value) => {
                  setValue("horas_presupuestadas", value, {
                    shouldValidate: true
                  })
                }}
              />
            </FormField>
            <FormErrors errors={errors.horas_presupuestadas} />
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr -10px", gap: 15, alignItems: "center" }}>
          <div className={styles.inputWrapper}>
            <label className={styles.label}>{t("frontend.auditoria.sprint.fields.objetivo")}</label>
            <FormField classRequired={errors.objetivo}>
              <TextArea
                onChange={(e, { value }) => {
                  setValue("objetivo", value, {
                    shouldValidate: true
                  })
                }}
              />
            </FormField>
            <FormErrors errors={errors.objetivo} />
          </div>
        </div>
        <div>
          <div className={styles.inputWrapper}>
            <label className={styles.label}>{t("frontend.auditoria.sprint.fields.config_affect_progress")}</label>
            <FormField border={false}>
              <InputYesNoChoice
                onChange={(v) => setValue("configs.affect_progress", v ? 1 : 0, { shouldValidate: true })}
              />
            </FormField>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "flex-end", paddingTop: 10, flex: 1 }}>
        <span style={{ flex: "1" }} />
        <Button text='btn_next' name='normal' type='big' option='normal' submit={true} />
      </div>
    </form>
  )
}
