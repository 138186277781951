import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { TextArea } from "semantic-ui-react"
import Button from "src/components/Button"
import Dialog from "src/components/Dialog"
import FormErrors from "src/components/FormErrors"
import FormField from "src/components/FormField"
import Input from "src/components/FormField/Input"
import InputYesNoChoice from "src/components/FormField/YesNoChoice"
import useTranslation from "src/hooks/useTranslation"
import RangeDatePicker from "src/macrocomponents/RangeDatePicker"
import { adminAuditSprint } from "src/services/auditSprint.service"
import styles from "../styles.module.scss"

export default function SprintEdit({ record, onClose }) {
  const auditData = useSelector((store) => store.project)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const initialData = {
    nombre: record.nombre,
    fecha_inicio: record.fecha_inicio,
    fecha_fin: record.fecha_fin,
    horas_presupuestadas: record.horas_presupuestadas,
    objetivo: record.objetivo,
    configs_affect_progress: record.configs?.affect_progress ?? 1
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: initialData
  })

  const formValidations = {
    nombre: { required: true },
    fecha_inicio: { required: true },
    fecha_fin: { required: true },
    horas_presupuestadas: { required: true },
    objetivo: { required: false },
    "configs.affect_progress": { required: false }
  }

  const onSubmit = (data) => {
    dispatch({ type: "PAGE_LOADING", value: true })
    adminAuditSprint(auditData.id)
      .PUT(record.id, data)
      .then(({ data }) => {
        const updatedData = auditData.sprints.map((item) => {
          return item.id == record.id ? { ...record, ...data } : item
        })
        dispatch({ type: "PROJECT_UPDATE_FIELD", field: "sprints", value: updatedData })
        dispatch({ type: "PAGE_LOADING", value: false })
        toast.success(t("main_ui.general.lb_record_updated"))
        onClose()
      })
  }

  useEffect(() => {
    Object.keys(formValidations).forEach((field) => {
      register(field, formValidations[field])
    })
  }, [register])

  return (
    <Dialog isOpen style={{ width: "80vw", display: "flex", flexDirection: "column" }}>
      <Dialog.Header theme='light' buttonClose handleClose={onClose}>
        <h4>{t("main_ui.general.edit_action", { action: "$t(frontend.auditoria.sprint.__name__)" })}</h4>
      </Dialog.Header>
      <Dialog.Body theme='light' style={{ flex: 1, overflow: "hidden" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)(e)
          }}
          className='width-100-react-datepicker'
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ display: "grid", gap: 15, overflow: "auto", paddingBottom: 10 }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 15, alignItems: "center" }}>
              <div>
                <label className={styles.label}>
                  {t("frontend.default.name")} {t("frontend.auditoria.sprint.__name__")}:
                  <span className={styles.required}> *</span>
                </label>
                <FormField classRequired={errors.nombre ? true : false}>
                  <Input
                    initialValue={!initialData ? "" : initialData.nombre}
                    onChange={(value) => {
                      setValue("nombre", value, {
                        shouldValidate: true
                      })
                    }}
                  />
                </FormField>
                <FormErrors errors={errors.nombre} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 15 }}>
              <RangeDatePicker
                labelConfig={{
                  start: (
                    <>
                      {t("frontend.default.start_date")}: <span className={styles.required}>*</span>
                    </>
                  ),
                  end: (
                    <>
                      {t("frontend.default.end_date")}: <span className={styles.required}>*</span>
                    </>
                  ),
                  position: "up",
                  bold: true,
                  twoPoints: false,
                  style: {
                    width: "fit-content"
                  }
                }}
                format='yyyy-MM-dd'
                initialStartDateValue={initialData.fecha_inicio}
                initialEndDateValue={initialData.fecha_fin}
                startChange={([completeDate, visualDate]) => {
                  setValue("fecha_inicio", visualDate, {
                    shouldValidate: true
                  })
                }}
                endChange={([completeDate, visualDate]) => {
                  setValue("fecha_fin", visualDate, {
                    shouldValidate: true
                  })
                }}
                errorsFormStart={errors.fecha_inicio}
                errorsFormEnd={errors.fecha_fin}
              />
              <div>
                <label className={styles.label}>
                  {t("frontend.default.estimated_hours")}: <span className={styles.required}>*</span>
                </label>
                <FormField classRequired={errors.horas_presupuestadas ? true : false}>
                  <Input
                    type='number'
                    min={0}
                    max={32767}
                    initialValue={initialData.horas_presupuestadas}
                    onChange={(value) => {
                      setValue("horas_presupuestadas", value, {
                        shouldValidate: true
                      })
                    }}
                  />
                </FormField>
                <FormErrors errors={errors.horas_presupuestadas} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr -10px", gap: 15, alignItems: "center" }}>
              <div>
                <label className={styles.label}>{t("frontend.auditoria.sprint.fields.objetivo")}: </label>
                <FormField classRequired={errors.objetivo}>
                  <TextArea
                    value={initialData.objetivo}
                    onChange={(e, { value }) => {
                      setValue("objetivo", value, {
                        shouldValidate: true
                      })
                    }}
                  />
                </FormField>
                <FormErrors errors={errors.objetivo} />
              </div>
            </div>
            <div>
              <div className={styles.inputWrapper}>
                <label className={styles.label}>{t("frontend.auditoria.sprint.fields.config_affect_progress")}</label>
                <FormField border={false}>
                  <InputYesNoChoice
                    initialValue={initialData.configs_affect_progress}
                    onChange={(v) => setValue("configs.affect_progress", v ? 1 : 0, { shouldValidate: true })}
                  />
                </FormField>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              paddingTop: 10,
              gap: 10,
              flex: 1
            }}
          >
            <span style={{ flex: "1" }} />
            <Button text='btn_cancel' name='warning' type='big' option='normal' handleClick={onClose} />
            <Button text='btn_save' name='normal' type='big' option='normal' submit={true} />
          </div>
        </form>
      </Dialog.Body>
    </Dialog>
  )
}
