import { useState } from "react"
import AdvancedWizard from "src/components/AdvancedWizard"
import Dialog from "src/components/Dialog"
import { frequencyType } from "src/constants/audit"
import useTranslation from "src/hooks/useTranslation"
import Scope from "src/macrocomponents/CreateAudit/Scope"
import AuditCreationModal from "src/macrocomponents/CreationModal"
import { createAudit } from "src/services/audit.service"
import useDispatch from "src/store"
import { addAudits } from "src/store/audits/actions"
import CreationType from "./CreationType"
import GeneralInformation from "./GeneralInformation"
import RiskEvaluation from "./RiskEvaluation"

export const closeType = Object.freeze({
  CREATED: "CREATED",
  CLOSE: "CLOSE"
})

export default function CreateAudit({ open = false, title = "Crear Auditoría", auditYear, onClose = () => {} }) {
  const dispatch = useDispatch()

  const [openAuditCreation, setOpenAuditCreation] = useState(false)
  const [generalInformationData, setGeneralInformationData] = useState({})
  const [riskEvaluationData, setRiskEvaluationData] = useState({
    selectedEvaluationId: null
  })
  const [displaySteps, setDisplaySteps] = useState(["generalInformation"])

  const { t } = useTranslation()

  const onCloseHandler = (type) => {
    onClose(type)
  }

  const onCreateAuditHandler = async ({ selectedRisks = [], gentipo, gentipo_param }) => {
    try {
      setOpenAuditCreation(true)

      const createData = {
        nombre: generalInformationData.auditName,
        tipo_frecuencia: generalInformationData.auditType,
        unidad_negocio: generalInformationData.businessUnit,
        proceso_tipo: generalInformationData.processType,
        auditoria_tipo: generalInformationData.typeAudit,
        ano_auditoria: generalInformationData.year,
        fecha_fin: generalInformationData.endDate,
        fecha_inicio: generalInformationData.startDate,
        horas_presupuestadas: generalInformationData.hours,
        lider: generalInformationData.auditLeader,
        horas_presupuestadas_lider: generalInformationData.leaderHours,
        evaluaciones: [riskEvaluationData.selectedEvaluationId],
        riesgos: selectedRisks,
        gentipo,
        gentipo_param,
        custom_paramlists: generalInformationData.custom_paramlists
      }

      if (riskEvaluationData.selectedEvaluationId) {
        createData.evaluaciones = [riskEvaluationData.selectedEvaluationId]
      }

      if (generalInformationData.company) {
        createData.empresa = generalInformationData.company
      }

      const result = await createAudit(createData)

      dispatch(addAudits([result.data], auditYear))

      onCloseHandler(closeType.CREATED)

      window.open(`/auditorias/plan/?auditoria-id=${result.data.id}&hoja-id=`, "_self")
    } catch (error) {
      throw new Error(error)
    } finally {
      setOpenAuditCreation(false)
    }
  }

  const goNextHandler = (goNext, data) => {
    setGeneralInformationData(data)

    let updatedSteps = []

    if (data.auditType === frequencyType.PROGRAMMED.value) {
      updatedSteps = ["generalInformation", "riskEvaluation", "scope"]

      // _index = 2
    } else if (data.auditType === frequencyType.SPECIAL.value) {
      updatedSteps = ["generalInformation", "creationType"]
    }

    setDisplaySteps(updatedSteps)
    goNext()
  }

  const goNextHandlerRiskEvaluation = (goNext, data) => {
    setRiskEvaluationData(data)
    goNext()
  }

  if (!open) {
    return null
  }

  return (
    <>
      <Dialog isOpen style={{ width: "80vw", height: "90vh" }}>
        <Dialog.Header theme='light' buttonClose handleClose={onCloseHandler.bind(this, closeType.CLOSE)}>
          <h4>{title}</h4>
        </Dialog.Header>
        <Dialog.Body theme='light' style={{ flex: 1, overflow: "hidden" }}>
          <AdvancedWizard
            initSelected={0}
            displaySteps={displaySteps}
            maxSteps={3}
            config={{
              generalInformation: {
                headerLabel: t("frontend.auditoria.creation.tab_infogeneral__title"),
                content: ({ goNext }) => {
                  return <GeneralInformation year={auditYear} onNext={goNextHandler.bind(this, goNext)} />
                }
              },
              riskEvaluation: {
                headerLabel: t("frontend.auditoria.creation.tab_assocevaluacion__title"),
                content: ({ goBack, goNext }) => {
                  // return null
                  return (
                    <RiskEvaluation
                      onBack={() => {
                        goBack()
                      }}
                      onNext={goNextHandlerRiskEvaluation.bind(this, goNext)}
                    />
                  )
                }
              },
              scope: {
                headerLabel: t("frontend.auditoria.creation.new_scope.title"),
                content: ({ goBack }) => {
                  return (
                    <Scope
                      onBack={() => {
                        goBack()
                      }}
                      onCreate={onCreateAuditHandler}
                      riskEvaluationData={riskEvaluationData.selectedEvaluationId}
                      empresaId={generalInformationData.company}
                    />
                  )
                }
              },
              creationType: {
                headerLabel: t("frontend.auditoria.creation.tab_type__title"),
                content: ({ goBack }) => {
                  return (
                    <CreationType
                      onBack={() => {
                        goBack()
                      }}
                      onCreate={onCreateAuditHandler}
                      additionalData={generalInformationData}
                    />
                  )
                }
              }
            }}
            onStepLeave={({ to }) => {
              if (to === 0) {
                setDisplaySteps(["generalInformation"])
              }
            }}
            onStepEnter={(stepIndex) => {
              if (stepIndex === 0) {
                setDisplaySteps(["generalInformation"])
              }
            }}
          />
        </Dialog.Body>
      </Dialog>
      {openAuditCreation && (
        <AuditCreationModal
          title={
            <>
              Creando auditoría <strong>{generalInformationData.auditName}</strong>,<br />
              por favor espere...
            </>
          }
          note={
            <div style={{ fontSize: "16px" }}>
              Las parametrizaciones requeridas para la realización de esta Auditoría están siendo procesadas en nuestro
              sistema.
              <br /> Una vez el proceso finalice, usted podrá ingresar a la Auditoría.
            </div>
          }
        />
      )}
    </>
  )
}
