import cx from "classnames"

export const ButtonAdd = ({ children, onClick }) => {
  return (
    <div style={{ cursor: "pointer" }} className={cx("ui button compact mini olive")} onClick={onClick}>
      <i className={cx("ui icon plus")}></i>
      {children}
    </div>
  )
}
