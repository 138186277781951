import cx from "classnames"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import AdvancedTable from "src/components/AdvancedTable"
import { ButtonAdd } from "src/components/ButtonAdd"
import { DeleteItemAction } from "src/components/DeleteItemAction"
import Dialog from "src/components/Dialog"
import Icon from "src/components/Icon"
import Loading from "src/components/Loading"
import Pill from "src/components/Pill"
import Tooltip from "src/components/Tooltip"
import { calculateAuditProgressColor, mapAuditStatus } from "src/helpers/audit"
import { dateFormatter } from "src/helpers/dateFormatter"
import useConfirm from "src/hooks/useConfirm"
import useTranslation from "src/hooks/useTranslation"
import { setAuditConfigs } from "src/services/audit.service"
import { adminAuditSprint } from "src/services/auditSprint.service"
import { SprintCreate } from "./Create"
import SprintEdit from "./Edit"
import styles from "./styles.module.scss"

const SprintRowActions = ({ row, onSelect, onDelete }) => {
  const [openEdit, setOpenEdit] = useState(false)

  const { t } = useTranslation()

  const handleEditClose = () => {
    setOpenEdit(false)
  }

  return (
    <>
      <div className={styles.button} onClick={() => onSelect(row)}>
        <Tooltip content={t("main_ui.general.btn_select")}>
          <i className='icon large eye' />
        </Tooltip>
      </div>

      <div className={styles.button} onClick={() => setOpenEdit(true)}>
        <Tooltip content={t("main_ui.general.btn_edit")}>
          <Icon name='pencil' />
        </Tooltip>
      </div>
      {openEdit && <SprintEdit record={row} onClose={handleEditClose} />}

      <DeleteItemAction
        disabledText={{ "frontend.auditoria.sprint.delete_current_tooltip": row.is_current }}
        confirmText={t("main_ui.general.delete_confirmation")}
        iconSize={row.is_current ? 24 : 16}
        onDelete={() => onDelete(row)}
      />
    </>
  )
}

const AuditSprintAdmin = ({ readOnly, onClose }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [openCreate, setOpenCreate] = useState(false)

  const { confirm: confirmEnable, Confirm: ConfirmEnable } = useConfirm()

  const audit = useSelector((state) => state.project)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!audit?.id || !audit?.configs?.enable_sprints) {
      return
    }

    adminAuditSprint(audit.id)
      .GET()
      .then(({ data }) => {
        dispatch({ type: "PROJECT_UPDATE_FIELD", field: "sprints", value: data })
        setLoading(false)
      })
  }, [audit?.id, audit?.configs?.enable_sprints])

  const handleClickEnableFeatureSprint = () => {
    confirmEnable(t("frontend.auditoria.sprint.btn_feature_enable__confirmation"))
      .then((isConfirmed) => {
        if (isConfirmed) {
          dispatch({ type: "PAGE_LOADING", value: true })
          return setAuditConfigs(audit.id, { enable_sprints: 1 })
        }
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "PROJECT_UPDATE_FIELD",
            field: "configs",
            value: { ...audit?.configs, ...res.data.configs }
          })
          dispatch({ type: "PAGE_LOADING", value: false })
        }
      })
  }

  const handleCreate = (createData) => {
    dispatch({ type: "PAGE_LOADING", value: "main_ui.general.lb_creating_record" })

    return adminAuditSprint(audit.id)
      .POST(createData)
      .then(({ data }) => {
        const updatedData = [...audit.sprints]
        updatedData.push(data)

        dispatch({ type: "PROJECT_UPDATE_FIELD", field: "sprints", value: updatedData })
        dispatch({ type: "PAGE_LOADING", value: false })
        toast.success(t("main_ui.general.lb_record_created"))
        setOpenCreate(false)
      })
  }

  const handleSelectRow = (row) => {
    dispatch({ type: "PAGE_LOADING", value: t("main_ui.general.lb_processing") })
    adminAuditSprint(audit.id)
      .POST_OBJECT_ACTION(row.id, "make_current")
      .then(() => {
        const updatedData = audit.sprints.map((item) => {
          return { ...item, is_current: item.id == row.id }
        })

        dispatch({ type: "PROJECT_UPDATE_FIELD", field: "sprints", value: updatedData })
        dispatch({ type: "PAGE_LOADING", value: false })
      })
    onClose()
  }

  const handleDeleteRow = (row) => {
    dispatch({ type: "PAGE_LOADING", value: t("main_ui.general.lb_processing") })
    adminAuditSprint(audit.id)
      .DELETE(row.id)
      .then(() => {
        const updatedData = audit.sprints.filter((item) => item.id != row.id)

        dispatch({ type: "PROJECT_UPDATE_FIELD", field: "sprints", value: updatedData })
        dispatch({ type: "PAGE_LOADING", value: false })
        toast.success(t("main_ui.general.lb_record_deleted"))
      })
  }

  const guessNextSprintName = () => {
    const lastSprint = audit.sprints[audit.sprints.length - 1]
    const matches = lastSprint.nombre.match(/(\d+)$/)

    if (!matches) {
      return "Sprint "
    }
    const nextIt = parseInt(matches[0]) + 1
    return lastSprint.nombre.substring(0, lastSprint.nombre.length - matches[0].length) + nextIt
  }

  const renderSprintsTable = () => {
    if (loading || audit.sprints === null) {
      return <Loading curtain={false} text='main_ui.general.lb_loading_records' />
    }
    return (
      <>
        <AdvancedTable
          showPaginator={false}
          data={audit.sprints}
          containerStyles={{ display: "flex", flexDirection: "column", flex: 1 }}
          displayedColumns={["name", "dateRange", "budgetedHours", "progress", "status", "actions"]}
          config={{
            rows: {
              name: {
                headerCell: t("frontend.default.name"),
                cell: (row) => {
                  return <div key={row.id}>{row.nombre}</div>
                }
              },
              dateRange: {
                headerCell: <div>{t("frontend.auditoria.fields.date_range")}</div>,
                cell: (row) => {
                  return <div>{`${dateFormatter(row.fecha_inicio) || ""} - ${dateFormatter(row.fecha_fin) || ""}`}</div>
                }
              },
              budgetedHours: {
                headerCell: <div>{t("frontend.auditoria.fields.estimated_hours")}</div>,
                cell: (row) => {
                  return <div>{row.horas_presupuestadas}</div>
                }
              },
              progress: {
                headerCell: <div>{t("frontend.auditoria.fields.progress")}</div>,
                cell: (row) => {
                  if (row.configs?.affect_progress == 0) {
                    return (
                      <div style={{ textAlign: "center" }}>
                        <Tooltip inline content={t("frontend.auditoria.sprint.lb_no_affect_progress")}>
                          <span>-</span>
                        </Tooltip>
                      </div>
                    )
                  }
                  return (
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <span style={{ display: "inline-block", marginRight: 10 }}>{row.progreso}%</span>
                      </div>
                      <div className={styles.progressBar}>
                        <div style={{ width: parseInt(row.progreso) + "%" }}></div>
                      </div>
                    </div>
                  )
                }
              },
              status: {
                headerCell: <div>{t("frontend.default.status")}</div>,
                cell: (row) => {
                  const status = mapAuditStatus(row.status)
                  return (
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                      <Pill color={status?.color} />
                      <span>{t(`frontend.auditoria.fields.status__choices.${status?.value}`, status.value)}</span>
                    </div>
                  )
                }
              },
              actions: {
                headerCell: !readOnly && (
                  <ButtonAdd onClick={() => setOpenCreate(true)}>{t("frontend.auditoria.sprint.btn_add")}</ButtonAdd>
                ),
                cell: (row) => {
                  if (readOnly) {
                    return null
                  }

                  return (
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                      <SprintRowActions row={row} onSelect={handleSelectRow} onDelete={handleDeleteRow} />
                    </div>
                  )
                },
                showOnHover: true
              }
            },
            rowClassName(row) {
              if (row.progreso == 100) {
                return "success"
              }
            }
          }}
        />
        {openCreate && (
          <SprintCreate
            defaultValues={{ nombre: guessNextSprintName() }}
            onSubmit={handleCreate}
            onClose={() => setOpenCreate(false)}
          />
        )}
      </>
    )
  }

  const renderSprintsDisableMessage = () => {
    return (
      <div className='ui message' style={{ textAlign: "center" }}>
        <div>
          <i className='icon info circle huge' />
        </div>
        <p style={{ marginBottom: 20 }}>{t("frontend.auditoria.sprint.message_feature_disabled")}</p>
        <button className='ui button tiny orange' onClick={handleClickEnableFeatureSprint}>
          <i className='icon check' /> {t("frontend.auditoria.sprint.btn_feature_enable")}
        </button>
        <ConfirmEnable type='YesNo' invertedButtons size='tiny' />
      </div>
    )
  }

  if (!audit) {
    return <Loading curtain={false} centered />
  }

  return (
    <div>
      <Dialog isOpen>
        <Dialog.Header theme='light' buttonClose handleClose={onClose}>
          <h4>{t("frontend.auditoria.sprint.admin__title")}</h4>
        </Dialog.Header>
        <Dialog.Body theme='light'>
          {audit.configs?.enable_sprints ? renderSprintsTable() : renderSprintsDisableMessage()}
        </Dialog.Body>
      </Dialog>
    </div>
  )
}

export const SprintSimpleTableList = ({ auditId }) => {
  const [records, setRecords] = useState(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    setRecords(null)

    adminAuditSprint(auditId)
      .GET()
      .then(({ data }) => {
        setRecords(data)
      })
  }, [auditId])

  const handleSprintClick = (sprint) => {
    return (ev) => {
      ev.preventDefault()
      dispatch({ type: "PAGE_LOADING", value: true })
      const promise = sprint.is_current
        ? Promise.resolve(null)
        : adminAuditSprint(auditId).POST_OBJECT_ACTION(sprint.id, "make_current")

      promise.then(() => {
        window.location.href = `/auditorias/plan/?auditoria-id=${auditId}`
      })
    }
  }

  if (records === null) {
    return (
      <div className='ui segment basic'>
        <div className='ui loader mini active' style={{ minHeight: 80 }}></div>
      </div>
    )
  }

  return (
    <div>
      <table className={cx("ui table compact striped celled", styles.table)}>
        <thead>
          <tr>
            <th>#</th>
            <th>{t("frontend.default.name")}</th>
            <th>{t("frontend.auditoria.fields.date_range")}</th>
            <th>{t("frontend.auditoria.fields.estimated_hours")}</th>
            <th>{t("frontend.auditoria.fields.progress")}</th>
            <th>{t("frontend.default.status")}</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record, k) => {
            const status = mapAuditStatus(record.status)

            return (
              <tr key={record.id} className={cx(record.progreso == 100 && styles.rowSuccess)}>
                <td>{k + 1}</td>
                <td>
                  <a style={{ cursor: "pointer", fontWeight: "bold" }} onClick={handleSprintClick(record)}>
                    {record.nombre}
                  </a>
                </td>
                <td>{`${dateFormatter(record.fecha_inicio) || ""} - ${dateFormatter(record.fecha_fin) || ""}`}</td>
                <td>{record.horas_presupuestadas}</td>
                <td>
                  <div style={{ textAlign: "center" }}>
                    <span style={{ display: "inline-block", marginRight: 10 }}>{record.progreso}%</span>
                    {record.configs?.affect_progress == 0 && (
                      <Tooltip inline content={t("frontend.auditoria.sprint.lb_no_affect_progress")}>
                        <span>
                          <i className='ui icon triangle exclamation orange' />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className={styles.progressBar}>
                    <div
                      style={{
                        width: parseInt(record.progreso) + "%",
                        backgroundColor: calculateAuditProgressColor(record.progreso)
                      }}
                    ></div>
                  </div>
                </td>

                <td>
                  <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    <Pill color={status?.color} />
                    <span>{t(`frontend.auditoria.fields.status__choices.${status?.value}`, status.value)}</span>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default AuditSprintAdmin
